import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import IN9008DayGallery from "components/Products/Lenses/IN-9008/in9008-day-gallery";
import IN9008CardGrid01 from "components/Products/Lenses/IN-9008/Card_Grid/in9008-lenses-cards";
import IN9008CardGrid02 from "components/Products/Lenses/IN-9008/Card_Grid/in9008-imageQ-cards";
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9008 FHD Lense Upgrades",
  "path": "/Products/Lenses/IN-9008/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "IN-9008 FHD Lense Upgrades",
  "image": "./P_SearchThumb_Products.png",
  "social": "/images/Search/P_SearchThumb_Products.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lenses_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-9008 FHD Lense Upgrades' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='IN-9008 FHD Lense Upgrades' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/Objektive/IN-9008/' locationFR='/fr/Products/Lenses/IN-9008/' crumbLabel="IN-9008 FHD" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "lenses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#lenses",
        "aria-label": "lenses permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lenses`}</h1>
    <h2 {...{
      "id": "in-9008-full-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-9008-full-hd",
        "aria-label": "in 9008 full hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9008 Full HD`}</h2>
    <h3 {...{
      "id": "example-images-day",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-images-day",
        "aria-label": "example images day permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example Images (Day)`}</h3>
    <p><strong parentName="p">{`4.2mm Default Wideangle Lense vs. 8mm Standard Lense and 16mm Tele Lense`}</strong></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <IN9008DayGallery mdxType="IN9008DayGallery" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "available-lenses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#available-lenses",
        "aria-label": "available lenses permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Available Lenses`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <IN9008CardGrid01 mdxType="IN9008CardGrid01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "image-quality",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#image-quality",
        "aria-label": "image quality permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Image Quality`}</h3>
    <h4 {...{
      "id": "wideangle-vs-resolution",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#wideangle-vs-resolution",
        "aria-label": "wideangle vs resolution permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wideangle vs Resolution`}</h4>
    <p>{`By using a lense with double the focal length, you will half the angle of view. That means that an object at the same distance, e.g. a license plate, will be displayed twice as big and be resolved by twice as many pixels by the image sensor. Writing, that is barely visible using a wide-angle lense, might be easily discernible with a standard or tele lense.`}</p>
    <p>{`It is important to choose the right lense for the surveillance task at hand. INSTAR allows you to find the perfect balance between field of view and resolution. The following example shows a license plate at a distance of `}<strong>{`approx. 15m`}</strong>{`, using lenses with different focal lengths (click on Gallery to enlarge).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <IN9008CardGrid02 mdxType="IN9008CardGrid02" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      